
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NoPage from './components/app/404Page';
import ListExpenseMui from './components/expenses/ListExpensesMui';
import Dashboard from './components/dashboard/Dashboard'
import SignIn from "./components/app/Login";
import 'react-toastify/dist/ReactToastify.css';
import AddNewExpense from "./components/expenses/AddNewExpense";
import { ADD_NEW_EXPENSE_ROUTE, CONTACT_LIST_ROUTE, DASHBOARD_EXPENSES_ROUTE, LOGIN_SCREEN_ROUTE, SEARCH_EXPENSES_ROUTE, SETTINGS_ROUTE, ADD_NEW_CONTACT_ROUTE, MEDICAL_REPORTS_ROUTE, ADD_NEW_MEDICAL_DETAIL_ROUTE, HEALTH_DATA_ROUTE, ADD_NEW_HEALTH_DATA_ROUTE, MEDICAL_TESTS_ROUTE, ADD_NEW_MEDICAL_TEST_ROUTE, MANAGE_FAMILY_MEMBERS_ROUTE } from "./constants/ExpenseManagerConstants";
import ContactList from "./components/contact_list/ContactList";
import AddNewContact from "./components/contact_list/AddNewContact";
import { MedicalDetails } from "./components/medical_records/medical_details/MedicalDetails";
import AddNewMedicalDetail from "./components/medical_records/medical_details/AddNewMedicalDetail";
import { HealthData } from "./components/medical_records/healthdata/HealthData";
import  AddNewHealthData  from "./components/medical_records/healthdata/AddNewHealthData";
import { MedicalTests } from "./components/medical_records/medical_tests/MedicalTests";
import  AddNewMedicalTest  from "./components/medical_records/medical_tests/AddNewMedicalTest";
import { ManageFamilyMembers } from "./components/familymembers/ManageFamilyMembers";


const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={LOGIN_SCREEN_ROUTE}/>} />
          <Route path={SEARCH_EXPENSES_ROUTE} Component={ListExpenseMui} />
          <Route path={LOGIN_SCREEN_ROUTE} Component={SignIn} />
          <Route path={DASHBOARD_EXPENSES_ROUTE} Component={Dashboard} />
          <Route path={ADD_NEW_EXPENSE_ROUTE} Component={AddNewExpense} />
          <Route path={CONTACT_LIST_ROUTE} Component={ContactList} />
          <Route path={ADD_NEW_CONTACT_ROUTE} Component={AddNewContact} />
          <Route path={MEDICAL_REPORTS_ROUTE} Component={MedicalDetails}/>
          <Route path={ADD_NEW_MEDICAL_DETAIL_ROUTE} Component={AddNewMedicalDetail}/>
          <Route path={HEALTH_DATA_ROUTE} Component={HealthData}/>
          <Route path={ADD_NEW_HEALTH_DATA_ROUTE} Component={AddNewHealthData}/>
          <Route path={MEDICAL_TESTS_ROUTE} Component={MedicalTests}/>
          <Route path={ADD_NEW_MEDICAL_TEST_ROUTE} Component={AddNewMedicalTest}/>
          <Route path={MANAGE_FAMILY_MEMBERS_ROUTE} Component={ManageFamilyMembers}/>
          <Route path="*" Component={NoPage} />
        </Routes>
      </BrowserRouter>
    </>
  );
};


export default App;

