import React, { useState, useEffect } from "react";
import BarChart from "./BarChart";
import "../app/index.css";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CssBaseline,
  useMediaQuery,
  Chip
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import AxiosHelper from "../../Helpers/AxiosHelper";
import Navbar from "../app/Navbar";
import DateUtils from "../../Helpers/DateUtils";
import { checkLogin } from "../../Helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
import MordenPieChart from "./MordenPieChart";
import {
  LOGIN_SCREEN_ROUTE,
  DASHBOARD_YEARLY_SELECTION,
  RELATIVE_EXPENSE_API_PATH,
  DASHBOARD_ALLTIME_SELECTION,
  DASHBOARD_MONTHLY_SELECTION,
} from "../../constants/ExpenseManagerConstants";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField"
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { toast, ToastContainer } from "react-toastify";
import { pickersLayoutClasses } from '@mui/x-date-pickers-pro';
import MordenBarChart from "./MordenBarChart";

const shortcutsItems = [
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const prevMonth = today.subtract(1, 'month');
      return [prevMonth.startOf('month'), prevMonth.endOf('month')];
    },
  },
  {
    label: "Current Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },
  {
    label: "Last Year",
    getValue: () => {
      const today = dayjs();
      const prevyear = today.subtract(1, "year");
      return [prevyear.startOf("year"), prevyear.endOf("year")];
    },
  },
  {
    label: "All Years",
    getValue: () => {
      const today = dayjs();
      const prevyear = today.subtract(3, "year");
      const lastYear = today.add(5, "year");
      return [prevyear.startOf("year"), lastYear.endOf("year")];
    },
  },
  { label: "Reset To Current Month", getValue: () => {
    const today = dayjs();
    return [today.startOf("month"), today.endOf("month")]} },
];


const mobileShortcutItems = [{
  label: "Last Month",
  getValue: () => {
    const today = dayjs();
    const prevMonth = today.subtract(1, 'month');
    return [prevMonth.startOf('month'), prevMonth.endOf('month')];
  },
},
{
  label: "Current Year",
  getValue: () => {
    const today = dayjs();
    return [today.startOf("year"), today.endOf("year")];
  },
},
{
  label: "Last Year",
  getValue: () => {
    const today = dayjs();
    const prevyear = today.subtract(1, "year");
    return [prevyear.startOf("year"), prevyear.endOf("year")];
  },
},]



const Dashboard = () => {
  const baseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_EXPENSE_API_PATH;

  const [barChartData, setBarChartData] = useState([]);
  const [barChartColumn, setBarChartColumn] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState();
  const isDeviceDesktop = useMediaQuery("(min-width:600px)");

  const CustomShortcuts = (props) => {
    const { items, onChange, isValid, changeImportance = 'accept', className } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue, changeImportance, item);
        },
        disabled: !isValid(newValue),
      };
    });
    return (
      <div className={className}>
        {resolvedItems.map((item) => (
          <Chip {...item} sx={{mr: 1}}/>
        ))}
      </div>
    )
  }

  const retrieveDashboardData = (
    fromDateToSet,
    toDateToSet,
    periodFrequency
  ) => {
    var fetchExpensesUrl =
      baseUrl + "?fromDate=" + fromDateToSet + "&toDate=" + toDateToSet;

    AxiosHelper.getData(fetchExpensesUrl).then((response) => {
      setTotalExpenses(getTotalExpenses(response.data));
      var newBarChartData = Object.values(groupAmountByDate(response.data));
      if (periodFrequency === DASHBOARD_YEARLY_SELECTION) {
        newBarChartData = Object.values(groupAmountByMonth(response.data));
        setBarChartColumn(barChartColumnsMonthly);
      } else if (periodFrequency === DASHBOARD_ALLTIME_SELECTION) {
        newBarChartData = Object.values(groupAmountByYear(response.data));
        setBarChartColumn(barChartColumnsYearly);
      }
      else if (periodFrequency === DASHBOARD_MONTHLY_SELECTION) {
        newBarChartData = Object.values(groupAmountByDate(response.data));
        setBarChartColumn(barChartColumnsByDate);

      }
      setBarChartData(newBarChartData);
      setPieChartData(Object.values(groupAmountByCategory(response.data)));
    });
  };


  const reloadOnNewDateRangeSelection = (inputDateRange) => {

    var inputDateRanegStrings = inputDateRange.toString();
    const dateRegex = /\w{3}, \d{1,2} \w{3} \d{4} \d{2}:\d{2}:\d{2} GMT/g;
    const dateArray = inputDateRanegStrings.match(dateRegex);

    var fromDate = dateArray[0];
    var toDate = dateArray[1];

    if (fromDate != null && toDate != null) {
      fromDate = DateUtils.convertDateToLocalTimeZone(fromDate);
      toDate = DateUtils.convertDateToLocalTimeZone(toDate);
      var dashboardFrequency = deriveFrequency(fromDate, toDate);
      retrieveDashboardData(fromDate, toDate, dashboardFrequency);
    }
    else {
      toast.error("Please select Date range from date and to date.")
    }
  };



  const deriveFrequency = (fromDate, toDate) => {
    var areDatesInSameMonth = DateUtils.areDatesInSameMonth(fromDate, toDate);
    var areDatesInSameYear = DateUtils.areDatesInSameYear(fromDate, toDate);
    if (areDatesInSameMonth) {
      return DASHBOARD_MONTHLY_SELECTION;
    }
    else if (areDatesInSameYear) {
      return DASHBOARD_YEARLY_SELECTION;
    }
    return DASHBOARD_ALLTIME_SELECTION;
  }
  const reloadDashboardForCurrentMonth = () => {
    var fromDateToSet = DateUtils.getFirstDateOfMonth();
    var toDateToSet = DateUtils.getLastDateOfMonth();
    var periodFrequency = DASHBOARD_MONTHLY_SELECTION;
    retrieveDashboardData(fromDateToSet, toDateToSet, periodFrequency);
  };

  function groupAmountByDate(objectArray) {
    return objectArray.reduce(function (accumulator, currentObject) {
      let property = "expensedate";
      let key = new Date(currentObject[property]).getDate();
      let amount1 = parseInt(currentObject["amount"]);
      if (!accumulator[key]) {
        accumulator[key] = { expensedate: key, amount: amount1 };
      } else {
        accumulator[key].amount = accumulator[key].amount + amount1;
      }
      return accumulator;
    }, {});
  }
  function groupAmountByMonth(objectArray) {
    return objectArray.reduce(function (accumulator, currentObject) {
      let property = "expensedate";
      let key = new Date(currentObject[property]).getMonth() + 1;
      let amount1 = parseInt(currentObject["amount"]);
      if (!accumulator[key]) {
        accumulator[key] = { expensedate: key, amount: amount1 };
      } else {
        accumulator[key].amount = accumulator[key].amount + amount1;
      }
      return accumulator;
    }, {});
  }

  function groupAmountByYear(objectArray) {
    return objectArray.reduce(function (accumulator, currentObject) {
      let property = "expensedate";
      let key = new Date(currentObject[property]).getFullYear();
      let amount1 = parseInt(currentObject["amount"]);
      if (!accumulator[key]) {
        accumulator[key] = { expensedate: key, amount: amount1 };
      } else {
        accumulator[key].amount = accumulator[key].amount + amount1;
      }
      return accumulator;
    }, {});
  }

  function getTotalExpenses(objectArray) {
    return objectArray.reduce(function (accumulator, currentObject) {
      let amount1 = parseInt(currentObject["amount"]);
      accumulator = accumulator + amount1;
      return accumulator;
    }, 0);
  }

  function groupAmountByCategory(objectArray) {
    return objectArray.reduce(function (accumulator, currentObject) {
      let property = "category";
      let key = currentObject[property];
      let amount1 = parseInt(currentObject["amount"]);
      if (!accumulator[key]) {
        accumulator[key] = { category: key, amount: amount1 };
      } else {
        accumulator[key].amount = accumulator[key].amount + amount1;
      }
      return accumulator;
    }, {});
  }


  const barChartColumnsByDate = React.useMemo(
    () => [
      {
        accessorKey: "expensedate", //simple recommended way to define a column
        header: "Date",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.amount, //alternate way
        id: "amount", //id required if you use accessorFn instead of accessorKey
        header: "Amount", //optional custom markup
      },
    ],
    []
  );

  const barChartColumnsMonthly = React.useMemo(
    () => [
      {
        accessorKey: "expensedate", //simple recommended way to define a column
        header: "Month",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.amount, //alternate way
        id: "amount", //id required if you use accessorFn instead of accessorKey
        header: "Amount", //optional custom markup
      },
    ],
    []
  );
  const barChartColumnsYearly = React.useMemo(
    () => [
      {
        accessorKey: "expensedate", //simple recommended way to define a column
        header: "Year",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.amount, //alternate way
        id: "amount", //id required if you use accessorFn instead of accessorKey
        header: "Amount", //optional custom markup
      },
    ],
    []
  );

  const pieChartColumns = React.useMemo(
    () => [
      {
        accessorKey: "category", //simple recommended way to define a column
        header: "Category",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.amount, //alternate way
        id: "amount", //id required if you use accessorFn instead of accessorKey
        header: "Amount", //optional custom markup
      },
    ],
    []
  );
  const navigate = useNavigate();

  useEffect(() => {
    const isUserLoggedIn = checkLogin();
    if (!isUserLoggedIn) {
      navigate(LOGIN_SCREEN_ROUTE);
    } else {
      setBarChartColumn(barChartColumnsByDate);
      reloadDashboardForCurrentMonth("Current Month");
    }
  }, [navigate]);


  return (
    <>
      <CssBaseline />
      <Box>
        <Navbar />
        <br />
        <Typography variant="h7" gutterBottom>
          Total expenses for selected period:- <b>{totalExpenses}</b>
        </Typography>
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {isDeviceDesktop ? (
            <DesktopDateRangePicker
              calendars={2}
              timeZone="local"
              format="DD-MMM-YYYY"
              sx={{ width: '350px' }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
              slots={{ field: SingleInputDateRangeField }}
              defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
              onAccept={reloadOnNewDateRangeSelection}
            />
          ) : (
            <>
              <MobileDateRangePicker
                calendars={1}
                timeZone="local"
                format="DD-MMM-YYYY"
                sx={{ width: '350px' }}
                slotProps={{
                  layout: {
                    sx: {
                      [`.${pickersLayoutClasses.shortcuts}`]: {
                        gridColumn: 2,
                        gridRow: 1,
                        display: "flex",
                        flexDirection: "row",
                        margin: 1
                      },
                    }
                  },
                  shortcuts: {
                    items: mobileShortcutItems
                  },
                  toolbar: {
                    hidden: true
                  }
                }}
                slots={{ field: SingleInputDateRangeField, shortcuts: CustomShortcuts }}
                defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                onAccept={reloadOnNewDateRangeSelection}
              />
            </>
          )}
        </LocalizationProvider>
        <br />

        <div className="mydiv">

          <Card sx={{ maxWidth: "relative", maxHeight: "relative" }}>
            <CardContent>
              <MordenPieChart data={pieChartData} />
            </CardContent>
          </Card>
          <br />
          <MaterialReactTable initialState={{pagination: {pageIndex: 0, pageSize: 5}}} data={pieChartData} columns={pieChartColumns} />
          <br />
          <Card sx={{ maxWidth: "relative", maxHeight: "relative" }}>
            <CardContent>
              {/* <BarChart data={barChartData} /> */}
              <MordenBarChart data={barChartData}/>
            </CardContent>
          </Card>
          <br />
          <MaterialReactTable
            data={barChartData}
            columns={barChartColumn}
          />
          <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        </div>
      </Box>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />    </>
  );
};

export default Dashboard;
