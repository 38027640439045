import { useEffect, useState } from "react";
import Navbar from "../../app/Navbar";
import { LOGIN_SCREEN_ROUTE } from "../../../constants/ExpenseManagerConstants";
import AxiosHelper from "../../../Helpers/AxiosHelper";
import { checkLogin } from "../../../Helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
import DateUtils from "../../../Helpers/DateUtils";
import { Autocomplete, Stack, FormControl, TextField, Typography, Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";

function AddNewMedicalDetail() {
    const familyMembers = JSON.parse(localStorage.getItem("familymembers"));
    const doctorCategories = ["General", "Cardiologist", "Dermatologist", "Emergency Medicine Specialist", "Allergist/Immunologist", "Anesthesiologist", "Colon and Rectal Surgeon", "Gastroenterologist", "Geriatric Medicine Specialist", "Neurologist", "Neurosurgeon", "Obstetrician/Gynecologist"];
    const [values, setValues] = useState({
        member_id: "",
        visit_date: DateUtils.getCurrentDateInMaterialInput(),
        current_age: 0,
        doctor_name: "",
        problem_description: "",
        prescription: "",
        doctor_category: "",
        files: [], // Change from file to files array
    });
    const navigate = useNavigate();

    function pluck(array, key) {
        return array.map(o => o[key]);
    }

    useEffect(() => {
        const isUserLoggedIn = checkLogin();
        if (!isUserLoggedIn) {
            navigate(LOGIN_SCREEN_ROUTE);
        } else {
            familyMembers.map((family_member) => {
                family_member.current_age = DateUtils.calculateYearsSinceData(family_member.date_of_birth);
                return family_member;
            })
        }
    }, [navigate]);

    const handleSubmit = async () => {
        let error = false;
        const requiredFields = ['member_id', 'visit_date', 'current_age', 'doctor_name', 'problem_description', 'prescription', 'doctor_category', 'files'];
        requiredFields.forEach(field => {
            if (!values[field] || (field === 'files' && values.files.length === 0)) {
                error = true;
                toast.error(`No ${field.replace('_', ' ')} found!`);
            }
        });

        if (!error) {
            const fileNames = values.files.map(file => file.name).join(",");
            const newMedicalDetail = {
                visit_date: values.visit_date,
                member_id: values.member_id,
                current_age: values.current_age,
                doctor_name: values.doctor_name,
                problem_description: values.problem_description,
                prescription: values.prescription,
                doctor_category: values.doctor_category,
                file_name: fileNames,
            };

            const formData = new FormData();
            values.files.forEach((file) => {
                formData.append("files", file);
            });

            const { data } = await AxiosHelper.postData(process.env.REACT_APP_API_BASEURL + "/api/medicaldetails", newMedicalDetail);
            if (data?.success) {
                toast.success("Medical detail added successfully!");

                axios.post(process.env.REACT_APP_API_BASEURL + "/api/files/multiple", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).catch(() => {
                    toast.error("Error uploading files!");
                });
            } else {
                toast.error("Error adding medical detail!");
            }
        }
    };

    const handleFileChange = (newFiles) => {
        setValues({
            ...values,
            files: Array.isArray(newFiles) ? newFiles : [newFiles],
        });
    };

    return (
        <>
            <Navbar />
            <Stack
                justifyContent="space-evenly"
                sx={{
                    width: "80%",
                    marginLeft: "10%",
                    marginRight: "10%"
                }}
            >
                <Typography
                    sx={{ marginTop: "5%" }}
                    textAlign="center"
                    component="h1"
                    variant="h5"
                    fullWidth
                >
                    ADD NEW MEDICAL DETAIL
                </Typography>
                <br />
                <FormControl fullWidth sx={{ mb: "8px" }}>
                    <Autocomplete
                        disablePortal
                        name="member_name"
                        options={pluck(familyMembers, "member_name")}
                        value={values.member_name}
                        onChange={(event, newValue) => {
                            setValues({
                                ...values,  
                                member_id: familyMembers.find(family_member => family_member.member_name === newValue).member_id, 
                                current_age: familyMembers.find(x => x.member_name === newValue).current_age
                            });
                        }}
                        renderInput={(params) => <TextField label="Member Name" {...params} />}
                    />
                </FormControl>
                <TextField
                    fullWidth
                    type="date"
                    name="visit_date"
                    label ="Doctor Visit date"
                    margin="normal"
                    defaultValue={DateUtils.getCurrentDateInMaterialInput()}
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="number"
                    name="current_age"
                    margin="normal"
                    value={values.current_age}
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    name="doctor_name"
                    label="Doctor Name"
                    margin="normal"
                    value={values.doctor_name}
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    name="problem_description"
                    label="Problem Description"
                    margin="normal"
                    value={values.problem_description}
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    name="prescription"
                    label="Prescription"
                    margin="normal"
                    value={values.prescription}
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <br />
                <Autocomplete
                    disablePortal
                    name="doctor_category"
                    options={doctorCategories}
                    value={values.doctor_category}
                    sx={{ mb: "8px" }}
                    onChange={(event, newValue) => {
                        setValues({
                            ...values,
                            doctor_category: newValue,
                        });
                    }}
                    renderInput={(params) => <TextField label="Doctor Category" {...params} />}
                />
                <br />
                <MuiFileInput
                    InputProps={{
                        inputProps: {
                            accept: 'image/*,application/pdf'
                        },
                        startAdornment: <AttachFileIcon />,
                    }}
                    multiple // Allow multiple file selection
                    placeholder="Upload Files"
                    value={values.files}
                    onChange={handleFileChange}
                />
                <br />
                <Button sx={{ mt: "16px" }} onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
            </Stack>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}
export default AddNewMedicalDetail;
