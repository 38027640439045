import { MaterialReactTable } from "material-react-table";
import { useState, useMemo, useCallback } from "react";
import { useEffect } from "react";
import {
  RELATIVE_FAMILY_MEMBERS_API_PATH,
  LOGIN_SCREEN_ROUTE,
} from "../../constants/ExpenseManagerConstants";
import AxiosHelper from "../../Helpers/AxiosHelper";
import Navbar from "../app/Navbar";
import {
  MenuItem,
  Box,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  TextField,
  Button,
  Autocomplete
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";
import { checkLogin } from "../../Helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const genderCategories = ["Male", "Female"];
const bloodGroupCategories = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];

export const ManageFamilyMembers = () => {
  const baseUrl =
    process.env.REACT_APP_API_BASEURL + RELATIVE_FAMILY_MEMBERS_API_PATH;
  const [familyMembers, setFamilyMembers] = useState([]);
  const navigate = useNavigate();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    const isUserLoggedIn = checkLogin();
    if (!isUserLoggedIn) {
      navigate(LOGIN_SCREEN_ROUTE);
    } else {
      AxiosHelper.getData(baseUrl).then((response) => {
        setFamilyMembers(response.data);
      });
    }
  }, [navigate]);

  const columns = useMemo(() => [
    {
      accessorKey: "member_id",
      header: "ID",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: "member_name",
      header: "Member Name",
    },
    {
      accessorKey: "date_of_birth",
      header: "Date Of Birth",
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        type: "date",
      }),
    },
    {
      accessorKey: "gender",
      header: "Gender",
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        select: true,
        children: genderCategories.map((state) => (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        )),
      }),
    },
    {
      accessorKey: "blood_group",
      header: "Blood Group",
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        select: true,
        children: bloodGroupCategories.map((state) => (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        )),
      }),
    },
  ]);
  const handleCreateNewRow = async (values) => {
    familyMembers.push(values)
    var newFamilyMember = {
      member_name: values.member_name,
      date_of_birth: values.date_of_birth,
      gender: values.gender,
      blood_group: values.blood_group
    };
    //alert('before calling data getting passed is :-'+ JSON.stringify(newExpense));
    var { data } = await AxiosHelper.postData(baseUrl,newFamilyMember);
    var toastMessage = "Failed to add Family Member.Please try again!";

    //alert('data result after adding expense is :--'+ JSON.stringify(data));
    if (data?.success === true) {
      toastMessage = "Family Member with name "+ data.data.member_name +" added successfully!";
     // alert(toastMessage);
      toast.success(toastMessage, {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else{
      alert(toastMessage);
      toast.error(toastMessage);
    }
      setFamilyMembers([...familyMembers]);
      ;
      localStorage.setItem("familymembers", JSON.stringify(familyMembers))
      setTimeout(() => window.location.reload(), 1400);
   // }
  };
  const handleDeleteRow = useCallback(
    (row) => {
      if (
        !window.confirm(
          `Are you sure you want to delete family member record of ${row.getValue(
            "member_name"
          )}`
        )
      ) {
        return;
      }
      familyMembers.splice(row.index, 1);
      const deleteUrl = baseUrl + "/" + row.original.member_id;
      AxiosHelper.deleteData(deleteUrl);
      setFamilyMembers([...familyMembers]);
      localStorage.setItem("familymembers", JSON.stringify(familyMembers))
      toast.success("Record of Family Member deleted successfully!", {
        position: "bottom-center",
        autoClose: 1400,
      });
      setTimeout(() => window.location.reload(), 1400);
    },
    [familyMembers]
  );

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    familyMembers[row.index] = values;
    const updateUrl = baseUrl + "/" + values.member_id;
    AxiosHelper.putData(updateUrl, values);
    setFamilyMembers([...familyMembers]);
    localStorage.setItem("familymembers", JSON.stringify(familyMembers))
    exitEditingMode();
    toast.success("Family Member edited successfully!", {
      position: "bottom-center",
      autoClose: 1400,
    });
    setTimeout(() => window.location.reload(), 1400); //required to exit editing mode and close modal
  };
  return (
    <>
      <Navbar />
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        data={familyMembers}
        columns={columns}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button
              color="secondary"
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
            >
              <Add />
            </Button>
          </>
        )}
      />
      <CreateNewFamilyMemberModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export const CreateNewFamilyMemberModal = ({
  open,
  columns,
  onClose,
  onSubmit,
}) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );
  let Error = false

  const handleSubmit = () => {

    if (values.member_name === '') {
        toast.error("No member name found!")
        Error= true
    }

    if (values.date_of_birth === '') {
      toast.error("No date of birth found!")
      Error = true

    }

    if (values.gender === '') {
      toast.error("No gender found!")
      Error = true

    }

    if (values.blood_group === '') {
      toast.error("No blood group found!")
      Error = true
    }

    if (Error === false) {
      onSubmit(values);
      onClose()
      setValues(() =>
        columns.reduce((acc, column) => {
          acc[column.accessorKey ?? ""] = "";
          return acc;
        }, {}))
    }
  }
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center" sx={{ color: "auto" }}>
        Create New Family Member
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField label="Member ID" disabled />
            <TextField
              label="Member Name"
              name="member_name"
              onChange={(e) =>
                setValues({
                  ...values,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <TextField
              type="date"
              name="date_of_birth"
              onChange={(e) =>
                setValues({
                  ...values,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <Autocomplete
                    disablePortal
                    name="gender"
                    options={genderCategories}
                    value={values.gender}
                    sx={{ mb: "8px" }}
                    onChange={(event, newValue) => {
                        setValues({
                            ...values,
                            gender: newValue,
                        });
                    }}
                    renderInput={(params) => <TextField label="Gender" {...params} />}
                />
                <Autocomplete
                    disablePortal
                    name="blood_group"
                    options={bloodGroupCategories}
                    value={values.blood_group}
                    sx={{ mb: "8px" }}
                    onChange={(event, newValue) => {
                        setValues({
                            ...values,
                            blood_group: newValue,
                        });
                    }}
                    renderInput={(params) => <TextField label="Blood Group" {...params} />}
                />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Family Member
        </Button>
      </DialogActions>
    </Dialog>
  );
};
