import Chart from 'react-apexcharts'
import React from 'react'

export default function MordenBarChart(props) {
  const getFlattenedAmounts = (inputData) => {
    var result = Object.values(inputData).map((record) => record.amount);
    return result;
  };
  const getFlattenedDates = (inputData) => {
    var result = Object.values(inputData).map((record) => record.expensedate);
    return result;
  };
    
    const state = {         
        series: [{
          name: 'Expense',
          data: getFlattenedAmounts(props.data)
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
            }
          },
          dataLabels: {
            enabled: false
          },
  
          
          xaxis: {
            categories: getFlattenedDates(props.data),
            position: 'top',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: function (val) {
                return "₹ "+val;
              }
            }
          
          },
          title: {
            text: 'Expenses Breakdown',
            floating: true,
            offsetY: 330,
            align: 'center',
          }
        },
      
      
      };    
    return (
        <Chart options={state.options} series={state.series} type='bar' height={350}/>
    )
}