import dayjs from 'dayjs';
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const moment = require('moment');
const format= 'YYYY-MM-DD';

const DateUtils = {

    formatDate : (inputDate) => {
        const date1 = new Date(inputDate);
        var getYear = date1.toLocaleString("default", { year: "numeric" });
        var getMonth = date1.toLocaleString("default", { month: "2-digit" });
        var getDay = date1.toLocaleString("default", { day: "2-digit" });
        var dateFormat = getYear + "-" + getMonth + "-" + getDay;
        return dateFormat;
    },
    getCurrentDateInMaterialInput : () => {
        const dateNow = new Date();
        const year = dateNow.getFullYear();
        const monthWithOffset = dateNow.getUTCMonth() + 1;
        const month =
          monthWithOffset.toString().length < 2
            ? `0${monthWithOffset}`
            : monthWithOffset;
        const date =
          dateNow.getUTCDate().toString().length < 2
            ? `0${dateNow.getUTCDate()}`
            : dateNow.getUTCDate();
      
        return `${year}-${month}-${date}`;
    },
    getFirstDateOfMonth : () => {
        return moment().startOf('month').format(format);
    },
    getFirstDateOfMonthWithotFormat : () => {
        return moment().startOf('month');
    },
    getLastDateOfMonth : () => {
        return moment().endOf('month').format(format);
    },
    getFirstDateOfLastMonth : () => {
        return moment().subtract(1, 'months').startOf('month').format(format);
    },
    getLastDateOfLastMonth : () => {
        return moment().subtract(1, 'months').endOf('month').format(format);
    },
    getFirstDateOfYear : () => {
        return moment().startOf('year').format(format);

    },
    getOldestDate : () => {
        return moment().subtract(60, 'months').endOf('month').format(format);

    },
    getLastDateOfYear : () => {
        return moment().endOf('year').format(format);

    },
    areDatesInSameMonth : (startDateStr, endDateStr) => {
        // Convert date strings to Date objects
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
    
        // Extract month and year from start and end dates
        const startMonth = startDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
        const startYear = startDate.getFullYear();
        const endMonth = endDate.getMonth() + 1;
        const endYear = endDate.getFullYear();
    
        // Check if start and end dates are in the same month and year
        if (startMonth === endMonth && startYear === endYear) {
            return true;
        } else {
            return false;
        }
    },
     areDatesInSameYear : (startDateStr, endDateStr) => {
        // Convert date strings to Date objects
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
    
        // Extract year from start and end dates
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
    
        // Check if start and end dates are in the same year
        return startYear === endYear;
    },
    convertDateToLocalTimeZone :(inputDate) => {
        const utc = require('dayjs/plugin/utc');
        const timezone = require('dayjs/plugin/timezone');
        dayjs.extend(utc);
        dayjs.extend(timezone);
        const parsedDate = dayjs.utc(inputDate);
        const localDate = parsedDate.local();
        const formattedDate = localDate.format('YYYY-MM-DD');
        return formattedDate;
    },
    calculateYearsSinceData : (date) => {
            // Convert the input date string to a Date object
    const inputDate = new Date(date);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    let yearsPassed = currentDate.getFullYear() - inputDate.getFullYear();

    // Adjust if the current month and day are before the input date
    if (currentDate.getMonth() < inputDate.getMonth() || 
        (currentDate.getMonth() === inputDate.getMonth() && 
        currentDate.getDate() < inputDate.getDate())) {
        yearsPassed--;
    }

    return yearsPassed;
    }
}
export default DateUtils;