const CommonUtils = {

     clearLocalStorage : () => {
        localStorage.removeItem("token");
        localStorage.removeItem("householdName");
        localStorage.removeItem("user");
        localStorage.removeItem("categories");
    }

}
export default CommonUtils;