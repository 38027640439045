import Chart from "react-apexcharts";
import {useMediaQuery} from "@mui/material"

export default function MordenPieChart(props) {
  const getFlattenedCategories = (inputData) => {
    var result = Object.values(inputData).map((record) => record.category);
    return result;
  };
  const getFlattenedAmounts = (inputData) => {
    var result = Object.values(inputData).map((record) => record.amount);
    return result;
  };

  var options = {
    options: {
      chart: {
        type: "pie",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true ,
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'Category',
              headerValue: 'Amount',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        },
      },
      labels: getFlattenedCategories(props.data),
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 410,
              height: 410,
            },
            legend: {
              position: "bottom"
            },
          },
        },
      ],
      legend: {
        position: "right",
      },
    },
    series: getFlattenedAmounts(props.data),
  };

  return (
    <Chart
      options={options.options}
      series={options.series}
      type="pie"
      width={672}
    />
  );
}
