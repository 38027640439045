import { Expense } from './TS';

export const data: Expense[] = [
  {
    id: 1234,
    expenseDate:  '2016-01-01',
    category: 'Grocery',
    amount: 1200,
    description: 'Dummy expense1',
  },
  {
    id: 1235,
    expenseDate: '02-Jan-2023',
    category: 'Others',
    amount: 490,
    description: 'Dummy Expense2',
  },
];

//50 us states array
export const categories_template = [
'DummyCategory1',
'DummyCategory2'

];
