//API PATHS
export const RELATIVE_AUTH_API_PATH="/api/auth";
export const RELATIVE_EXPENSE_API_PATH="/api/expenses";
export const RELATIVE_CONTACTS_API_PATH="/api/contacts";
export const RELATIVE_FAMILY_MEMBERS_API_PATH="/api/familymembers"
export const RELATIVE_MEDICAL_DETAILS_API_PATH="/api/medicaldetails"
export const RELATIVE_FILES_API_PATH="/api/files"

//ROUTES
export const LOGIN_SCREEN_ROUTE="/signin";
export const SEARCH_EXPENSES_ROUTE="/home";
export const DASHBOARD_EXPENSES_ROUTE= "/dashboard";
export const SETTINGS_ROUTE="/settings";
export const ADD_NEW_EXPENSE_ROUTE="/addNewExpense";
export const CONTACT_LIST_ROUTE="/contactList"
export const ADD_NEW_CONTACT_ROUTE="/addNewContact"
export const MEDICAL_REPORTS_ROUTE="/medicalDetails" 
export const ADD_NEW_MEDICAL_DETAIL_ROUTE="/addNewMedicalDetail"
export const HEALTH_DATA_ROUTE="/healthData"
export const ADD_NEW_HEALTH_DATA_ROUTE="/addNewHealthData"
export const MEDICAL_TESTS_ROUTE="/medicalTests"
export const ADD_NEW_MEDICAL_TEST_ROUTE="/addNewMedicalTest"
export const MANAGE_FAMILY_MEMBERS_ROUTE="/manageFamilyMembers"

//DASHBOARD SCREEN CONSTANTS
export const DASHBOARD_YEARLY_SELECTION = "yearly"; 
export const DASHBOARD_MONTHLY_SELECTION = "monthly"; 
export const DASHBOARD_ALLTIME_SELECTION = "alltime"; 