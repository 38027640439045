import { useMemo, useState, useCallback, useEffect } from "react";
import Navbar from "../app/Navbar";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip, Button } from "@mui/material"
import { Delete, Edit, Add } from "@mui/icons-material"
import { checkLogin } from "../../Helpers/AuthHelper";
import { useNavigate } from "react-router-dom"
import { LOGIN_SCREEN_ROUTE, RELATIVE_CONTACTS_API_PATH } from "../../constants/ExpenseManagerConstants";
import AxiosHelper from "../../Helpers/AxiosHelper";

export default function ContactList() {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_CONTACTS_API_PATH

    useEffect(() => {
        const isUserLoggedIn = checkLogin();
        if (!isUserLoggedIn) {
            navigate(LOGIN_SCREEN_ROUTE)
        }
        else {

            AxiosHelper.getData(baseUrl).then((response) => {
              setTableData(response.data);
            });
          }
    }, [navigate]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "Id",
            },
            {
                accessorKey: "name",
                header: "Name",
            },
            {
                accessorKey: "age",
                header: "Age",
            },
            {
                accessorKey: "address",
                header: "Address",
            },
            {
                accessorKey: "mobilenumber",
                header: "Mobile Number"
            },
            {
                accessorKey: "emailaddress",
                header: "Email Address"
            },
        ],
        []
    );

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete contact ${row.getValue("name")}`
                )
            ) {
                return;
            }
            const updatedData = tableData.filter(item => item.id !== row.original.id);
            setTableData(updatedData);
            var idToDelete = tableData[row.index].id
            var deleteUrl = baseUrl + "/" + idToDelete
            AxiosHelper.deleteData(deleteUrl)
        },
        [tableData]
    );

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        const updatedData = tableData.map(item => {
            if (item.id === row.original.id) {
                return values;
            }
            return item;
        });
        setTableData(updatedData);
        var idToUpdate = tableData[row.index].id
        var editUrl = baseUrl + "/" + idToUpdate
        AxiosHelper.putData(editUrl, values)
        exitEditingMode();
    };

    return (
        <>
            <Navbar />
            <MaterialReactTable renderTopToolbarCustomActions={() => (
                <>
                    <Button
                        color="secondary"
                        variant="contained"
                        href="/addNewContact"
                    >
                        <Add />
                    </Button>
                </>
            )} onEditingRowSave={handleSaveRowEdits} enableRowActions={true} displayColumnDefOptions={{
                "mrt-row-actions": {
                    muiTableHeadCellProps: {
                        align: "center",
                    },
                    size: 120,
                },
            }} renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow placement="right" title="Edit">
                        <IconButton onClick={() => table.setEditingRow(row)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Delete">
                        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Box>
            )} columns={columns} data={tableData} />
        </>
    );
}
