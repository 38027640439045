import { useState } from "react";

import {
  TextField,
  Stack,
  Button,
  Typography,
  Autocomplete,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { categories_template } from "./makeData.ts";
import AxiosHelper from "../../Helpers/AxiosHelper.js";
import Navbar from "../app/Navbar";
import { FormControl } from "@mui/material";
import DateUtils from "../../Helpers/DateUtils";
import { RELATIVE_EXPENSE_API_PATH } from "../../constants/ExpenseManagerConstants.js";

const baseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_EXPENSE_API_PATH;

export default function AddNewExpense() {
  var categories = localStorage.getItem("categories");
  if (!categories) {
    categories = categories_template;
  } else {
    categories = localStorage.getItem("categories").split(",");
  }
  const [values, setValues] = useState({
    expensedate: "",
    amount: 0,
    description: "",
    category: "",
  });

  const materialDateInput = DateUtils.getCurrentDateInMaterialInput();
  var error = false;

  const handleSubmit = async () => {
    if (values.expensedate === "") {
      values.expensedate = materialDateInput;
    } 

    if (values.amount === 0) {
      toast.error("No amount found!");
      error = true;
    }

    if (values.description === "") {
      toast.error("No description found!");
      error = true;
    }

    if (values.category === "") {
      toast.error("No category found!");
      error = true;
    }
    if (error === false) {

      var newExpense = {
        expensedate: values.expensedate,
        category: values.category,
        description: values.description,
        amount: values.amount,
      };
      //alert('before calling data getting passed is :-'+ JSON.stringify(newExpense));
      var { data } = await AxiosHelper.postData(baseUrl, newExpense);
      var toastMessage = "Failed to add expense.Please try again!";

      //alert('data result after adding expense is :--'+ JSON.stringify(data));
      if (data?.success === true) {
        toastMessage =
          "Expense for description "+ data.data.description  +" added successfully!.New id is:-"+ data.data.id+
          ".";
       
        toast.success(toastMessage, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => window.location.reload(), 1400);

      } else {
        alert(toastMessage);
        toast.error(toastMessage);
      }
    }
  };

  return (
    <>
      <Navbar />
      <Stack
        justifyContent="space-evenly"
        sx={{
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%"
        }}
      >
        <Typography
          sx={{ marginTop: "5%" }}
          textAlign="center"
          component="h1"
          variant="h5"
          fullWidth
        >
          ADD NEW EXPENSE
        </Typography>
        <TextField
          fullWidth
          type="date"
          name="expensedate"
          label = "Expense Date"
          margin="normal"
          required
          defaultValue={materialDateInput}
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <TextField
          label="Expense Amount"
          margin="normal"
          name="amount"
          required
          fullWidth
          type="number"
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <TextField
          id="outlined-basic"
          margin="normal"
          required
          fullWidth
          name="description"
          label="Expense Description"
          variant="outlined"
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <br />
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            name="category"
            options={categories}
            value={values.category}
            onChange={(event, newValue) => {
              setValues({
                ...values,
                category: newValue,
              });
            }}
            renderInput={(params) => <TextField label="Category" {...params} />}
          />
        </FormControl>
         
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
