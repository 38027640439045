import { useEffect, useMemo, useState, useCallback } from "react"
import Navbar from "../../app/Navbar"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { checkLogin } from "../../../Helpers/AuthHelper"
import { LOGIN_SCREEN_ROUTE, RELATIVE_FAMILY_MEMBERS_API_PATH, RELATIVE_MEDICAL_DETAILS_API_PATH, RELATIVE_FILES_API_PATH} from "../../../constants/ExpenseManagerConstants"
import AxiosHelper from "../../../Helpers/AxiosHelper"
import DateUtils from "../../../Helpers/DateUtils"
import { MaterialReactTable } from "material-react-table"
import { saveAs } from "file-saver"
import { Box, IconButton, Tooltip, MenuItem, Button } from "@mui/material"
import { Edit, Delete, CloudDownload } from '@mui/icons-material'
import { toast, ToastContainer } from "react-toastify"

export const HealthData = () => {
    const [data, setData] = useState([])
    const familyMembers = JSON.parse(localStorage.getItem("familymembers"));
    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_API_BASEURL + "/api/healthdata"
    const familyMembersBaseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_FAMILY_MEMBERS_API_PATH
    const fileBaseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_FILES_API_PATH + "/"
    const doctorCategories = ["General", "Cardiologist", "Dermatologist", "Emergency Medicine Specialist", "Allergist/Immunologist", "Anesthesiologist", "Colon and Rectal Surgeon", "Gastroenterologist", "Geriatric Medicine Specialist", "Neurologist", "Neurosurgeon", "Obstetrician/Gynecologist"]

    function pluck(array, key) {
        return array.map(o => o[key]);
    }

    useEffect(() => {
        const isUserLoggedIn = checkLogin()
        if (!isUserLoggedIn) {
            navigate(LOGIN_SCREEN_ROUTE)
        }
        else {
            AxiosHelper.getData(baseUrl).then((response) => {
                var modified_response = response.data.map((health_data) => {
                    var extraRow = {"member_name"  : familyMembers.find(family_member => family_member.member_id === health_data.member_id).member_name};
                    return {...health_data, ...extraRow}
                })
                setData(modified_response);
            });
        }
    }, [navigate, baseUrl])

    const saveFile = (fileUrl, fileName) => {
        saveAs(fileUrl, fileName)
    }
    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete health data of ${row.getValue("member_name")} with id ${row.original.health_tracking_id}?`
                )
            ) {
                return;
            }
            const updatedData = data.filter(item => item.health_tracking_id !== row.original.health_tracking_id);
            setData(updatedData);
            var idToDelete = data[row.index].health_tracking_id
            var deleteUrl = baseUrl + "/" + idToDelete
            axios.delete(deleteUrl)
            toast.success("Health Data deleted successfully!", {
                position: "bottom-center",
                autoClose: 1400,
            });
            setTimeout(() => window.location.reload(), 1400);
        },
        [data, baseUrl, fileBaseUrl]
    );
    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        const idToUpdate = row.original.health_tracking_id;
        const editUrl = `${baseUrl}/${idToUpdate}`;

        try {
            const response = await AxiosHelper.putData(editUrl, values);
             

            const updatedData = data.map(item => {
                if (item.health_tracking_id === idToUpdate) {
                    return values;
                }
                return item;
            });
            setData(updatedData);
            exitEditingMode();
            toast.success("Health Data edited successfully!", {
                position: "bottom-center",
                autoClose: 1400,
            });
            setTimeout(() => window.location.reload(), 1400);
        } catch (error) {
            console.error("Error occurred while saving row edits");
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "health_tracking_id",
                header: "ID",
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "text"
                })
            },
            {
                accessorKey: "member_name",
                header: "Member Name",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    select: true,
                    children: familyMembers.map((state) => (
                        <MenuItem key={state.member_name} value={state.member_name}>
                            {state.member_name}
                        </MenuItem>
                    )),
                })
            },
            {
                accessorKey: "effective_date",
                header: "Effective Date",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "date"
                })
            },
            {
                accessorKey: "weight",
                header: "Weight (Kg)",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "number"
                })
            },
            {
                accessorKey: "height",
                header: "Height (Cms)",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "number"
                })
            },
            {
                accessorKey: "bp_low_reading",
                header: "BP Low Reading",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "number"
                })
            },
            {
                accessorKey: "bp_high_reading",
                header: "BP High Reading",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "number"
                }),
            },
            {
                accessorKey: "other_medical_condition",
                header: "Other Medical Condition",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "text"
                })
            },
        ]
    )
    return (
        <>
            <Navbar />
            <MaterialReactTable data={data} columns={columns} onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Tooltip arrow placement="right" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )} enableRowActions={true} displayColumnDefOptions={{
                    "mrt-row-actions": {
                        muiTableHeadCellProps: {
                            align: "center",
                        },
                        size: 120,
                    },
                }} />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}