import { useEffect, useMemo, useState, useCallback } from "react"
import Navbar from "../../app/Navbar"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { checkLogin } from "../../../Helpers/AuthHelper"
import { LOGIN_SCREEN_ROUTE, RELATIVE_FAMILY_MEMBERS_API_PATH, RELATIVE_MEDICAL_DETAILS_API_PATH, RELATIVE_FILES_API_PATH} from "../../../constants/ExpenseManagerConstants"
import AxiosHelper from "../../../Helpers/AxiosHelper"
import DateUtils from "../../../Helpers/DateUtils"
import { MaterialReactTable } from "material-react-table"
import { saveAs } from "file-saver"
import { Box, IconButton, Tooltip, MenuItem, Button } from "@mui/material"
import { Edit, Delete, CloudDownload } from '@mui/icons-material'
import { toast, ToastContainer } from "react-toastify"

export const MedicalDetails = () => {
    const [data, setData] = useState([])
    const familyMembers = JSON.parse(localStorage.getItem("familymembers"));
    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_MEDICAL_DETAILS_API_PATH
    const familyMembersBaseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_FAMILY_MEMBERS_API_PATH
    const fileBaseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_FILES_API_PATH ;
    const doctorCategories = ["General", "Cardiologist", "Dermatologist", "Emergency Medicine Specialist", "Allergist/Immunologist", "Anesthesiologist", "Colon and Rectal Surgeon", "Gastroenterologist", "Geriatric Medicine Specialist", "Neurologist", "Neurosurgeon", "Obstetrician/Gynecologist"]

    function pluck(array, key) {
        return array.map(o => o[key]);
    }

    useEffect(() => {
        const isUserLoggedIn = checkLogin()
        if (!isUserLoggedIn) {
            navigate(LOGIN_SCREEN_ROUTE)
        }
        else {
            AxiosHelper.getData(baseUrl).then((response) => {
                var modified_response = response.data.map((medical_detail) => {
                    var extraRow = {"member_name"  : familyMembers.find(family_member => family_member.member_id === medical_detail.member_id).member_name};
                    return {...medical_detail, ...extraRow}
                })
                setData(modified_response);
            });
        }
    }, [navigate, baseUrl, familyMembersBaseUrl])
    const saveFile = (fileUrl, fileName) => {
        saveAs(fileUrl, fileName)
    }
    const handleDeleteRow = useCallback(
        (row) => {
           const uniqueReference = `${row.getValue("member_name")} with visit id ${row.original.visit_id}`;
            if (
                !window.confirm(
                    `Are you sure you want to delete medical detail of `+ uniqueReference +` ?`
                )
            ) {
                return;
            }
            const updatedData = data.filter(item => item.visit_id !== row.original.visit_id);
            setData(updatedData);
            var idToDelete = data[row.index].visit_id
            var deleteUrl = baseUrl + "/" + idToDelete;
            const deleteFiles = row.original.file_name.split(',').map(file => axios.delete(`${fileBaseUrl}/${file}`));
            AxiosHelper.deleteData(deleteUrl);
            Promise.all(deleteFiles).then(() => {
              toast.success("Medical Details and associated files for "+ uniqueReference +" deleted successfully!", {
                  position: "bottom-center",
                  autoClose: 1400,
              });
              setTimeout(() => window.location.reload(), 1400);
          });
        },
        [data, baseUrl, fileBaseUrl]
    );
    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        const idToUpdate = row.original.visit_id;
        const editUrl = `${baseUrl}/${idToUpdate}`;

        try {
            const response = await AxiosHelper.putData(editUrl, values);
             

            const updatedData = data.map(item => {
                if (item.visit_id === idToUpdate) {
                    return values;
                }
                return item;
            });
            setData(updatedData);
            exitEditingMode();
            toast.success("Medical Detail edited successfully!", {
                position: "bottom-center",
                autoClose: 1400,
            });
            setTimeout(() => window.location.reload(), 1400);
        } catch (error) {
            console.error("Error occurred while saving row edits");
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "visit_id",
                header: "ID",
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "text"
                })
            },
            {
                accessorKey: "member_name",
                header: "Member Name",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    select: true,
                    children: familyMembers.map((state) => (
                        <MenuItem key={state.member_name} value={state.member_name}>
                            {state.member_name}
                        </MenuItem>
                    )),
                })
            },
            {
                accessorKey: "visit_date",
                header: "Visit Date",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "date"
                })
            },
            {
                accessorKey: "current_age",
                header: "Current Age",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "number"
                })
            },
            {
                accessorKey: "doctor_name",
                header: "Doctor Name",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "text"
                })
            },
            {
                accessorKey: "doctor_category",
                header: "Doctor Category",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    select: true,
                    children: doctorCategories.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                }),
            },
            {
                accessorKey: "problem_description",
                header: "Problem Description",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "text"
                })
            },
            {
                accessorKey: "prescription",
                header: "Prescription",
                muiTableBodyCellEditTextFieldProps: () => ({
                    variant: "outlined",
                    type: "text"
                })
            },
            {
                header: "Prescription Files",
                accessorKey: "file_name",
                Cell: ({ cell }) => (
                    cell.getValue().split(',').map((file, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            color="primary"
                            startIcon={<CloudDownload/>}
                            onClick={() => saveFile(fileBaseUrl + file, file)}
                            sx={{ marginBottom: "5px", mr: "8px" }}
                        >
                             File {index + 1}
                        </Button>
                    ))
                ),
                muiTableBodyCellEditTextFieldProps: () => ({
                    style: {
                        display: "none"
                    }
                })
            }
        ]
    )
    return (
        <>
            <Navbar />
            <MaterialReactTable data={data} columns={columns} onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Tooltip arrow placement="right" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )} enableRowActions={true} displayColumnDefOptions={{
                    "mrt-row-actions": {
                        muiTableHeadCellProps: {
                            align: "center",
                        },
                        size: 120,
                    },
                }} />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}