import { useEffect, useState } from "react";
import Navbar from "../../app/Navbar";
import { LOGIN_SCREEN_ROUTE } from "../../../constants/ExpenseManagerConstants";
import AxiosHelper from "../../../Helpers/AxiosHelper";
import { checkLogin } from "../../../Helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
import DateUtils from "../../../Helpers/DateUtils";
import { Autocomplete, Stack, FormControl, TextField, Typography, Button } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { MuiFileInput } from "mui-file-input";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

function AddNewMedicalTest() {
    const familyMembers = JSON.parse(localStorage.getItem("familymembers"));
    const [values, setValues] = useState({
        test_description: "",
        member_id: "",
        test_date: DateUtils.getCurrentDateInMaterialInput(),
        current_age: 0,
        lab_name: "",
        prescribed_by: "",
        file: []
    });
    const navigate = useNavigate();
    function pluck(array, key) {
        return array.map(o => o[key]);
    }

    useEffect(() => {
        const isUserLoggedIn = checkLogin();
        if (!isUserLoggedIn) {
            navigate(LOGIN_SCREEN_ROUTE);
        } else {
            familyMembers.map((family_member) => {
                family_member.current_age = DateUtils.calculateYearsSinceData(family_member.date_of_birth);
                return family_member;
            })
        }
    }, [navigate]);

    const handleSubmit = async () => {
        var error = false;
        if (values.test_description === "") {
            error = true;
            toast.error("No test description found!");
        }
        if (values.member_id === "") {
            error = true;
            toast.error("No member id found!");
        }
        if (values.test_date === "") {
            error = true;
            toast.error("No test date found!");
        }
        if (values.lab_name === "") {
            error = true;
            toast.error("No lab name found!");
        }
        if (values.prescribed_by === "") {
            error = true;
            toast.error("No prescribed by found!");
        }
        if (values.file.length === 0) {
            error = true;
            toast.error("No file found!");
        }
        if (!error) {
            const fileNames = values.file.map(file => file.name).join(",");
            const newMedicalTest = {
                test_description: values.test_description,
                member_id: values.member_id,
                test_date: values.test_date,
                current_age: values.current_age,
                lab_name: values.lab_name,
                prescribed_by: values.prescribed_by,
                file_name: fileNames
            };
            const formData = new FormData();
            values.file.forEach((file) => {
                formData.append("files", file);
            });
            const { data } = await AxiosHelper.postData(process.env.REACT_APP_API_BASEURL + "/api/medicaltests", newMedicalTest);
            if (data?.success) {
                toast.success("Medical test added successfully!");
                axios.post(process.env.REACT_APP_API_BASEURL + "/api/files/multiple", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).catch(() => {
                    toast.error("Error uploading files!");
                });
            } else {
                toast.error("Error adding medical test!");
            }
        }
    };

    const handleFileChange = (newFiles) => {
        setValues({
            ...values,
            file: Array.isArray(newFiles) ? newFiles : [newFiles],
        });
    };

    return (
        <>
            <Navbar />
            <Stack
                justifyContent="space-evenly"
                sx={{
                    width: "80%",
                    marginLeft: "10%",
                    marginRight: "10%"
                }}
            >
                <Typography
                    sx={{ marginTop: "5%" }}
                    textAlign="center"
                    component="h1"
                    variant="h5"
                    fullWidth
                >
                    ADD NEW MEDICAL TEST
                </Typography>
                <FormControl fullWidth sx={{ mb: "8px" }}>
                    <Autocomplete
                        disablePortal
                        name="member_name"
                        options={pluck(familyMembers, "member_name")}
                        value={values.member_name}
                        onChange={(event, newValue) => {
                            setValues({
                                ...values,
                                member_id: familyMembers.find(family_member => family_member.member_name === newValue).member_id,
                            });
                        }}
                        renderInput={(params) => <TextField label="Member Name" {...params} />}
                    />
                </FormControl>
                <TextField
                    fullWidth
                    type="text"
                    name="test_description"
                    label="Test Description"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="date"
                    name="test_date"
                    margin="normal"
                    label = "Medical Test effective Date"
                    defaultValue={DateUtils.getCurrentDateInMaterialInput()}
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="text"
                    name="lab_name"
                    label="Lab Name"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="text"
                    name="prescribed_by"
                    label="Prescribed By"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <br />
                <MuiFileInput
                    InputProps={{
                        inputProps: {
                            accept: 'image/*,application/pdf'
                        },
                        startAdornment: <AttachFileIcon />,
                    }}
                    multiple // Allow multiple file selection
                    placeholder="Upload Files"
                    value={values.file}
                    onChange={handleFileChange}
                />
                <Button sx={{ mt: "16px" }} onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
            </Stack>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}

export default AddNewMedicalTest;
