import { Navigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import CommonUtils from './CommonUtils';

var bcrypt = require("bcryptjs");

const commonHeaders = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_LICENCE;
}

const CheckError = (res) => {
    var errorMessage = "Some Error while login. Please try again";
    if (res.status === 401) {
        errorMessage= 'Token Expired or password not correct. Please login Again.';
    
    } else if(res.status === 404){
        errorMessage= 'Username is not correct.Please login again.';
    }
    toast.error(errorMessage);

    CommonUtils.clearLocalStorage();
    return <Navigate to={`/signin`} />
}

const errorData = (error) => {
     
    CheckError(error.response)
    return error.response;
}


const AxiosHelper = {
    getData: async (url, formData = null) => {
        try {
            commonHeaders();
            var hashValue =  bcrypt.hashSync(url, 10);
            axios.defaults.headers.common['expense-key'] = hashValue;
            var data = axios.get(url, { params: formData })
            return data;
        } catch (error) {
            return errorData(error)
        }
    },
    postData: async (url, formData, type) => {
        try {
            commonHeaders()
            var data = await axios.post(url, formData, { headers: { "Content-Type": type ? "multipart/form-data" : "application/json" } })
            return data;
        } catch (error) {
            return errorData(error)
        }
    },
    putData: async (url, formData, type) => {
        try {
            commonHeaders()
            var data = await axios.put(url, formData, { headers: { "Content-Type": type ? "multipart/form-data" : "application/json" } })
            return data;
        } catch (error) {
            return errorData(error)
        }
    },
    deleteData: async (url) => {
        try {
            commonHeaders()
            var data = await axios.delete(url)
            return data;
        } catch (error) {
            return errorData(error)
        }
    }
}


export default AxiosHelper;