import React, { useCallback, useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  Select,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import DateUtils from "../../Helpers/DateUtils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Delete, Edit } from "@mui/icons-material";
import { data, categories_template } from "./makeData.ts";
import AxiosHelper from "../../Helpers/AxiosHelper";
import Navbar from "../app/Navbar";
import AddIcon from "@mui/icons-material/Add";
import { checkLogin } from "../../Helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
import { LOGIN_SCREEN_ROUTE, RELATIVE_EXPENSE_API_PATH } from "../../constants/ExpenseManagerConstants.js";


const ListExpenseMui = (props) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(() => data);
  const [validationErrors, setValidationErrors] = useState({});
  const [theme, setTheme] = useState("light");
  const baseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_EXPENSE_API_PATH;
  const navigate = useNavigate();
  var categories = categories_template;

  useEffect(() => {
    const isUserLoggedIn = checkLogin();
    if (!isUserLoggedIn) {
      navigate(LOGIN_SCREEN_ROUTE);
    } else {

      AxiosHelper.getData(baseUrl).then((response) => {
        response.data.map((expense) => {
          var dateToFormat = expense.expensedate;
          expense.expensedate = DateUtils.formatDate(dateToFormat);
          return expense;
        });
        setTableData(response.data);
      });
    }
  }, [navigate]);
  var categoriesLocalStorage = localStorage.getItem("categories");
  if (categoriesLocalStorage) {
    categories = categoriesLocalStorage.split(",");
  }
  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });

  const changeTheme = () => {
    if (theme === "light") setTheme("dark");
    else setTheme("light");
  };

  const handleCreateNewRow = async (values) => {
    tableData.push(values);
  

    var newExpense = {
      expensedate: values.expensedate,
      category: values.category,
      description: values.description,
      amount: values.amount,
    };
    //alert('before calling data getting passed is :-'+ JSON.stringify(newExpense));
    var { data } = await AxiosHelper.postData(baseUrl,newExpense);
    var toastMessage = "Failed to add expense.Please try again!";

    //alert('data result after adding expense is :--'+ JSON.stringify(data));
    if (data?.success === true) {
      toastMessage = "Expense with description "+ data.data.description +" added successfully!";
     // alert(toastMessage);
      toast.success(toastMessage, {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else{
      alert(toastMessage);
      toast.error(toastMessage);
    }
      setTableData([...tableData]);
      ;
      setTimeout(() => window.location.reload(), 1400);
   // }
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      const updateUrl = baseUrl + "/" + values.id;
      AxiosHelper.putData(updateUrl, values);
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      exitEditingMode();
      toast.success("Expense edited successfully!", {
        position: "bottom-center",
        autoClose: 1400,
      });
      setTimeout(() => window.location.reload(), 1400); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("description")}`
        )
      ) {
        return;
      }
      tableData.splice(row.index, 1);
      const deleteUrl = baseUrl + "/" + row.original.id
      AxiosHelper.deleteData(deleteUrl);
      setTableData([...tableData]);
      toast.success("Expense deleted successfully!", {
        position: "bottom-center",
        autoClose: 1400,
      });
      setTimeout(() => window.location.reload(), 1400);
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "email"
              ? validateEmail(event.target.value)
              : cell.column.id === "age"
                ? validateAge(+event.target.value)
                : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "expensedate",
        header: "Expense Date",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          type: "date"
        }),
      },
      {
        accessorKey: "amount",
        header: "Expense Amount",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          type: "number",
        }),
      },
      {
        accessorKey: "description",
        header: "Expense Description",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "text",
        }),
      },
      {
        accessorKey: "category",
        header: "Expense Category",
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: categories.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          )),
        },
        type: "select",
      },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <Navbar />
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button
              color="secondary"
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
            >
              <AddIcon />
            </Button>
          </>
        )}
        initialState={{
          sorting: [
            {
              id: "expensedate", //sort by age by default on page load
              desc: true,
            },
          ],
        }}
      />
      <CreateNewExpenseModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewExpenseModal = ({ open, columns, onClose, onSubmit }) => {
  var categories = localStorage.getItem("categories");
  if (!categories) {
    categories = categories_template;
  } else {
    categories = localStorage.getItem("categories").split(",");
  }
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );

  const [datePickerValue, setDatePickerValue] = useState();
  let Error = false;
  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`
  const handleSubmit = () => {

    if (values.expensedate === '') {
        values.expensedate = materialDateInput;
    }

    if (values.amount === '') {
      toast.error("No amount found!")
      Error = true

    }

    if (values.description === '') {
      toast.error("No description found!")
      Error = true

    }

    if (values.category === '') {
      toast.error("No category found!")
      Error = true
    }

    if (Error === false) {
      onSubmit(values);
      onClose()
      setValues(() =>
        columns.reduce((acc, column) => {
          acc[column.accessorKey ?? ""] = "";
          return acc;
        }, {}))
    }
  }
  
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center" sx={{ color: "auto" }}>
        Create New Expense
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) => {
              if (column.accessorKey === "category") {
                return (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      {categories.map((category) => {
                        return (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                );
              }
              if (column.accessorKey === "expensedate") {
                return (
                  <TextField
                    type="date"
                    key={column.accessorKey}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                    defaultValue={materialDateInput}
                  />
                );
              }
              if (column.accessorKey === "amount") {
                return (
                  <TextField
                    type="number"
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
                );
              }
              if (column.accessorKey === "id") {
                return (
                  <>
                    <TextField
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      disabled
                    />
                  </>
                );
              } else {
                return (
                  <>
                    <TextField
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </>
                );
              }
            })}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Expense
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default ListExpenseMui;
