import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Menu } from "@mui/material";
import { useMediaQuery } from "@mui/material"
import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import CommonUtils from "../../Helpers/CommonUtils";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from '@mui/icons-material/Settings';
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import ContactsIcon from '@mui/icons-material/Contacts';
import ListSubheader from '@mui/material/ListSubheader';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ArticleIcon from '@mui/icons-material/Article';
import ScienceIcon from '@mui/icons-material/Science';

import {
  ADD_NEW_EXPENSE_ROUTE,
  DASHBOARD_EXPENSES_ROUTE,
  LOGIN_SCREEN_ROUTE,
  SEARCH_EXPENSES_ROUTE,
  CONTACT_LIST_ROUTE,
  ADD_NEW_CONTACT_ROUTE,
  MEDICAL_REPORTS_ROUTE,
  ADD_NEW_MEDICAL_DETAIL_ROUTE,
  HEALTH_DATA_ROUTE,
  ADD_NEW_HEALTH_DATA_ROUTE,
  MEDICAL_TESTS_ROUTE,
  ADD_NEW_MEDICAL_TEST_ROUTE,
  MANAGE_FAMILY_MEMBERS_ROUTE,
} from "../../constants/ExpenseManagerConstants";

export default function Navbar(props) {
  const user = localStorage.getItem("user");
  const householdName = localStorage.getItem("householdName");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const isDeviceDesktop = useMediaQuery("(min-width:600px)");
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>  
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <ListSubheader>Expenses</ListSubheader>
            <Link
              to={ADD_NEW_EXPENSE_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <AddIcon />
                &nbsp;Add New Expense
              </MenuItem>
            </Link>
            <Link
              to={DASHBOARD_EXPENSES_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <DashboardIcon />
                &nbsp;Dashboard
              </MenuItem>
            </Link>
            <Link
              to={SEARCH_EXPENSES_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <ManageSearchIcon />
                &nbsp;Search Expenses
              </MenuItem>
            </Link>
            <ListSubheader>Contact List</ListSubheader>
            <Link
              to={CONTACT_LIST_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <ContactsIcon />
                &nbsp;Contact List
              </MenuItem>
            </Link>
            <Link
              to={ADD_NEW_CONTACT_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <AddIcon />
                &nbsp;Add New Contact
              </MenuItem>
            </Link>
            <ListSubheader>Medical Details</ListSubheader>
            <Link
              to={MEDICAL_REPORTS_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <MedicalInformationIcon/>
                &nbsp;Medical Details
              </MenuItem>
            </Link>
            <Link
              to={ADD_NEW_MEDICAL_DETAIL_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <AddIcon/>
                &nbsp;Add New Medical Detail
              </MenuItem>
            </Link>
            <ListSubheader>Health Data</ListSubheader>
            <Link
              to={HEALTH_DATA_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <ArticleIcon/>
                &nbsp;Health Data
              </MenuItem>
            </Link>
            <Link
              to={ADD_NEW_HEALTH_DATA_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <AddIcon/>
                &nbsp;Add New Health Data
              </MenuItem>
            </Link>
            <ListSubheader>Medical Tests</ListSubheader>
            <Link
              to={MEDICAL_TESTS_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <ScienceIcon/>
                &nbsp;Medical Tests
              </MenuItem>
            </Link>
            <Link
              to={ADD_NEW_MEDICAL_TEST_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <AddIcon/>
                &nbsp;Add New Medical Test
              </MenuItem>
            </Link>
            <ListSubheader>Family Members</ListSubheader>
            <Link
              to={MANAGE_FAMILY_MEMBERS_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <SettingsIcon/>
                &nbsp;Manage Family Members
              </MenuItem>
            </Link>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {householdName}
          </Typography>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <AccountCircle />
            &nbsp;
            {isDeviceDesktop ? (user) : ''}
          </IconButton>
          <Menu
            id="basic-menu1"
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {isDeviceDesktop ? '' : (<ListSubheader>{user}</ListSubheader>)}
            <Link
              to={LOGIN_SCREEN_ROUTE}
              style={{ textDecoration: "none", color: "black" }}
            >  
              <MenuItem onClick={CommonUtils.clearLocalStorage}>
                <LogoutIcon />
                &nbsp;Log Out
              </MenuItem>
            </Link>
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}
