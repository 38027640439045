import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { toast, ToastContainer } from 'react-toastify';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AxiosHelper from '../../Helpers/AxiosHelper';
import { useEffect } from 'react';
import { ADD_NEW_EXPENSE_ROUTE, RELATIVE_AUTH_API_PATH } from '../../constants/ExpenseManagerConstants';

const defaultTheme = createTheme();

export default function SignIn() {

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const navigate = useNavigate();

  const authBaseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_AUTH_API_PATH;

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = () => {
    setIsSubmitted(true);
  }

  const handleKeyDown = (event) => {
    if(event.key === 'Enter')
    {
      handleSubmit();
    }
  }

  useEffect(() => {
    if (isSubmitted)
      (async () => {
        var { data } = await AxiosHelper.postData(authBaseUrl, {
          username: username,
          password: password
        })
        if (data?.status === true) {

          console.log('whole data is :--'+ JSON.stringify(data?.data));
          localStorage.setItem('token', data?.data?.token);
          localStorage.setItem('categories', data?.data?.categories);
          localStorage.setItem('householdName', data?.data?.householdName)
          localStorage.setItem('user', data?.data?.name)
          localStorage.setItem('familymembers', JSON.stringify(data?.data?.familymembers))

          toast.success("Successfully Login..!!");

          return navigate(ADD_NEW_EXPENSE_ROUTE);
        }
        else {
           
        }
        setIsSubmitted(false)
      })();
  }, [username, password, navigate, isSubmitted, authBaseUrl]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Username"
              name="name"
              autoComplete="name"
              onChange={handleUsernameChange}
              autoFocus
            />
            <TextField
              margin="normal"
              type = "password"
              required
              fullWidth
              id="name"
              label="Password"
              name="password"
              autoComplete="name"
              onChange={handlePasswordChange}
              autoFocus
              onKeyDown={handleKeyDown}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}

export var loggedIn;