import { useState } from "react";

import {
  TextField,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "../app/Navbar";
import AxiosHelper from "../../Helpers/AxiosHelper";
import { RELATIVE_CONTACTS_API_PATH } from "../../constants/ExpenseManagerConstants";

export default function AddNewContact() {
  const [values, setValues] = useState({
    name: "",
    mobilenumber: "",
    address: "",
    emailaddress: "",
    age: 0,
  });
  const baseUrl = process.env.REACT_APP_API_BASEURL + RELATIVE_CONTACTS_API_PATH;

  const handleSubmit = async () => {
    var error = false;
    if (values.name === "") {
      toast.error("No name found!");
      error = true;
    }

    if (values.mobilenumber === "") {
      toast.error("No mobile number found!");
      error = true;
    }

    if (values.address === "") {
      toast.error("No address found!");
      error = true;
    }

    if (values.emailaddress === "") {
      toast.error("No email address found!")
      error = true
    }

    if (values.age === 0 | null) {
      toast.error("No age found!")
      error = true
    }

    if (error === false) {
      var newContact = {
        name: values.name,
        mobilenumber: values.mobilenumber,
        address: values.address,
        emailaddress: values.emailaddress,
        age: values.age
      };
      var {data} = await AxiosHelper.postData(baseUrl, newContact)
      var toastMessage = "Failed to add contact. Please try again!";
      if (data?.success) {
        toastMessage = "Contact with name "+ data.data.name +" has been added successfully!.New id is:-"+ data.data.id+
        ".";
        toast.success(toastMessage)
      }
      else {
        toast.error(toastMessage)
      }
    }
  }
  return (
    <>
      <Navbar />
      <Stack
        justifyContent="space-evenly"
        sx={{
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%"
        }}
      >
        <Typography
          sx={{ marginTop: "5%" }}
          textAlign="center"
          component="h1"
          variant="h5"
          fullWidth
        >
          ADD NEW CONTACT
        </Typography>
        <TextField
          fullWidth
          label="Name"
          name="name"
          margin="normal"
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <TextField
          label="Mobile Number"
          margin="normal"
          name="mobilenumber"
          fullWidth
          type="text"
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <TextField
          margin="normal"
          fullWidth
          name="address"
          label="Address"
          variant="outlined"
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <TextField
          margin="normal"
          fullWidth
          name="emailaddress"
          label="Email Address"
          type="email"
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <TextField
          margin="normal"
          fullWidth
          name="age"
          label="Age"
          type="number"
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
