import { useEffect, useState } from "react";
import Navbar from "../../app/Navbar";
import { LOGIN_SCREEN_ROUTE } from "../../../constants/ExpenseManagerConstants";
import AxiosHelper from "../../../Helpers/AxiosHelper";
import { checkLogin } from "../../../Helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
import DateUtils from "../../../Helpers/DateUtils";
import { Autocomplete, Stack, FormControl, TextField, Typography, Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

function AddNewHealthData() {

    const familyMembers = JSON.parse(localStorage.getItem("familymembers"));
    const [values, setValues] = useState({
         member_name: "",
         member_id : "",
         weight: 0,
         height: 0,
         effective_date : "",
         bp_low_reading: 0,
         bp_high_reading: 0,
         other_medical_condition: "",
    })
    const navigate = useNavigate()

    function pluck(array, key) {
        return array.map(o => o[key]);
    }

    useEffect(() => {
        const isUserLoggedIn = checkLogin()
        if (!isUserLoggedIn) {
            navigate(LOGIN_SCREEN_ROUTE)
        }
    }, [navigate])
    const handleSubmit = async () => {
        var error = false
        if (values.member_name === "") {
            toast.error("No member name found!")
            error = true
        }
        else{
            values.member_id = familyMembers.
            find(family_member => family_member.member_name === values.member_name).member_id;
        }
        if (values.weight === 0) {
            toast.error("No weight found!")
            error = true
        }
        if (values.height === 0) {
            toast.error("No height found!")
            error = true
        }
        if (values.bp_low_reading === 0) {
            toast.error("No bp low reading found!")
            error = true
        }
        if (values.effective_date === "") {
            values.effective_date = DateUtils.getCurrentDateInMaterialInput();
        }
        if (values.bp_high_reading === 0) {
            toast.error("No bp high reading found!")
            error = true
        }
        if (values.other_medical_condition === "") {
            toast.error("No other medical condition found!")
            error = true
        }
        if (!error) {
            const newHealthData = {
                member_name: values.member_name,
                member_id: values.member_id,
                effective_date : values.effective_date,
                weight: values.weight,
                height: values.height,
                bp_low_reading: values.bp_low_reading,
                bp_high_reading: values.bp_high_reading,
                other_medical_condition: values.other_medical_condition,
            }
            const {data} = await AxiosHelper.postData(process.env.REACT_APP_API_BASEURL + "/api/healthdata", newHealthData)
            if (data?.success) {
                toast.success("Health Data added successfully!")
            }
            else {
                toast.error("Error adding health data!")
            }
        }
    }

    return (
        <>
            <Navbar />
            <Stack
                justifyContent="space-evenly"
                sx={{
                    width: "80%",
                    marginLeft: "10%",
                    marginRight: "10%"
                }}
            >
                <Typography
                    sx={{ marginTop: "5%" }}
                    textAlign="center"
                    component="h1"
                    variant="h5"
                    fullWidth
                >
                    ADD NEW HEALTH DATA
                </Typography>
                <FormControl fullWidth sx={{mb: "8px"}}>
                    <Autocomplete
                        disablePortal
                        name="member_name"
                        options={pluck(familyMembers, "member_name")}
                        value={values.member_name}
                        onChange={(event, newValue) => {
                            setValues({
                                ...values,
                                member_name: newValue,
                            });
                        }}
                        renderInput={(params) => <TextField label="Member Name" {...params} />}
                    />
                </FormControl>
                <TextField
                    fullWidth
                    type="date"
                    name="effective_date"
                    label = "Measurement Date"
                    margin="normal"
                    required
                    defaultValue={DateUtils.getCurrentDateInMaterialInput()}
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                    />
                <TextField
                    fullWidth
                    type="number"
                    name="weight"
                    label="Weight (Kg)"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="number"
                    name="height"
                    label="Height (cm)"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="number"
                    name="bp_low_reading"
                    label="BP Low Reading"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="number"
                    name="bp_high_reading"
                    label="BP High Reading"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <TextField
                    fullWidth
                    type="text"
                    name="other_medical_condition"
                    label="Other Medical Condition"
                    margin="normal"
                    required
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <Button sx={{mt: "16px"}} onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
            </Stack>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}
export default AddNewHealthData;